import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";
import { bindActionCreators } from "redux";
import { actionCreaters } from "../../../Redux";
import { dosAdd } from "../../../schema";
import UserService from "../../../services/user.service";
import AdminFooter from "../includes/AdminFooter";
import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";
import { Helmet } from "react-helmet";

export default function AddDos() {
  const TITLE = "Casper Cash | Add DOS";
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);

  const state = useSelector((state) => state.stateVals);
  const { accessToken, id } = state;

  const [loading, setLoading] = useState(false);

  const [userState, setUserState] = useState([]);
  const [userCity, setUserCity] = useState([]);
  const [userCityLoader, setUserCityLoader] = useState(false);

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await UserService.getUserState();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.name,
          value: value.id,
        });
      });
      setUserState([{ key: "SELECT STATE", value: "" }, ...results]);
    };
    getUserState();
  }, []);

  const changeUserCity = (e) => {
    setUserCity([]);
    const getUserCity = async () => {
      setUserCityLoader(true);
      const { data } = await UserService.getCityByStateId(e.target.value);
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.city,
          value: value.id,
        });
      });
      setUserCity([...results]);
      setUserCityLoader(false);
    };

    if (e.target.value !== "") {
      getUserCity();
    }
  };
  const addDos = async (values, action) => {
    try {
      setLoading(true);
      const response = await UserService.addNewDos(values, id);

      setLoading(false);
      toast.success("DOS has been added!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      action.resetForm();
    } catch (err) {
      console.log(err);
      if (err?.response?.status === 401) {
        setLoading(false);

        toast.error("Error, Try Again Later!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        setLoading(false);
        toast.error("Error, Unprocessable Content!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        let errorData = {};
        errorData["fname"] =
          err.response?.data &&
          err.response?.data?.first_name &&
          err.response?.data?.first_name[0];
        errorData["lname"] =
          err.response?.data &&
          err.response?.data?.last_name &&
          err.response?.data?.last_name[0];
        errorData["address1"] =
          err.response?.data &&
          err.response?.data?.address1 &&
          err.response?.data?.address1[0];
        errorData["city"] =
          err.response?.data &&
          err.response?.data?.city_id &&
          err.response?.data?.city_id[0];
        errorData["state"] =
          err.response?.data &&
          err.response?.data?.state_id &&
          err.response?.data?.state_id[0];
        errorData["zip"] =
          err.response?.data &&
          err.response?.data?.zip &&
          err.response?.data?.zip[0];
        errorData["username"] =
          err.response?.data &&
          err.response?.data?.username &&
          err.response?.data?.username[0];
        errorData["email"] =
          err.response?.data &&
          err.response?.data?.email &&
          err.response?.data?.email[0];
        errorData["new_password"] =
          err.response?.data &&
          err.response?.data?.password &&
          err.response?.data?.password[0];

        setErrors(errorData);
      } else {
        setLoading(false);

        toast.error("Error, Some thing went wrong!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setErrors,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      fname: "",
      lname: "",
      username: "",
      email: "",
      address1: "",
      state: "",
      city: "",
      new_password: "",
      confirm_password: "",
    },
    validationSchema: dosAdd,
    onSubmit: (values, action) => {
      addDos(values, action);
    },
  });

  useEffect(() => {
    const getIp = async () => {
      const res = await axios.get("https://api.ipify.org?format=json");

      const weIp = res.data.ip;
      secureLocalStorage.setItem("ip", weIp);
    };
    getIp();
  }, [handleSubmit]);

  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="semi-dark">
        <div className="wrapper">
          <ToastContainer />

          <HeaderSidebar />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>ADD DOS</h2>
                </div>
                <form
                  className="profile-form"
                  onSubmit={handleSubmit}
                  noValidate
                >
                  <div className="main-content-box">
                    <h2 className="manage-territories-heading">Information</h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.fname && touched.fname ? "is-danger" : ""
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="First-Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="fname"
                            value={values.fname || ""}
                          />
                          <label>
                            First Name{" "}
                            <span className="d-inline-block">* </span>
                          </label>
                          {errors.fname && touched.fname ? (
                            <p className="help is-danger">{errors.fname}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.lname && touched.lname ? "is-danger" : ""
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Last Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="lname"
                            value={values.lname || ""}
                          />
                          <label>
                            Last Name <span className="d-inline-block">* </span>
                          </label>
                          {errors.lname && touched.lname ? (
                            <p className="help is-danger">{errors.lname}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.address1 && touched.address1
                              ? "is-danger"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="address1"
                            value={values.address1 || ""}
                          />
                          <label>
                            Address <span className="d-inline-block">* </span>
                          </label>
                          {errors.address1 && touched.address1 ? (
                            <p className="help is-danger">{errors.address1}</p>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.state && touched.state ? "is-danger" : ""
                          }`}
                        >
                          <select
                            className="form-select"
                            onChange={(e) => {
                              changeUserCity(e);
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            name="state"
                            value={values.state || ""}
                            required
                          >
                            {/* <option value="">SELECT STATE</option> */}
                            {userState.map((res) => {
                              return (
                                <option key={res.value} value={res.value}>
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>
                          <label>
                            State <span className="d-inline-block">* </span>
                          </label>
                          {errors.state && touched.state ? (
                            <p className="help is-danger">{errors.state}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="select-leading">
                          {userCityLoader ? (
                            <span
                              className="spinner-border spinner-border-sm"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          ) : null}
                          <div
                            className={`form-floating ${
                              errors.city && touched.city ? "is-danger" : ""
                            }`}
                          >
                            <select
                              className="form-select"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="city"
                              value={values.city || ""}
                              required
                            >
                              <option value="">SELECT CITY</option>
                              {userCity.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>
                            <label>
                              City <span className="d-inline-block">* </span>
                            </label>
                            {errors.city && touched.city ? (
                              <p className="help is-danger">{errors.city}</p>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>

                    <h2 className="manage-territories-heading">
                      Email Information
                    </h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.username && touched.username
                              ? "is-danger"
                              : ""
                          }`}
                        >
                          <input
                            type="text"
                            className="form-control"
                            placeholder="User Name"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="username"
                            value={values.username || ""}
                          />
                          <label>Username</label>
                          {errors.username && touched.username ? (
                            <p className="help is-danger">{errors.username}</p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.email && touched.email ? "is-danger" : ""
                          }`}
                        >
                          <input
                            type="email"
                            className="form-control"
                            placeholder="E-mail Address"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="email"
                            value={values.email || ""}
                          />
                          <label>
                            E-mail Address{" "}
                            <span className="d-inline-block">* </span>
                          </label>
                          {errors.email && touched.email ? (
                            <p className="help is-danger">{errors.email}</p>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    <h2 className="manage-territories-heading">
                      Password Information
                    </h2>
                    <div className="row">
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.new_password && touched.new_password
                              ? "is-danger"
                              : ""
                          }`}
                        >
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="new_password"
                            value={values.new_password || ""}
                          />
                          <label>Password</label>
                          {errors.new_password && touched.new_password ? (
                            <p className="help is-danger">
                              {errors.new_password}
                            </p>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div
                          className={`form-floating ${
                            errors.confirm_password && touched.confirm_password
                              ? "is-danger"
                              : ""
                          }`}
                        >
                          <input
                            type="password"
                            className="form-control"
                            placeholder="Confirm Password"
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="confirm_password"
                            value={values.confirm_password || ""}
                          />
                          <label>Confirm Password </label>
                          {errors.confirm_password &&
                          touched.confirm_password ? (
                            <p className="help is-danger">
                              {errors.confirm_password}
                            </p>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row justify-content-center">
                      <div className="col-lg-4">
                        <button
                          type="submit"
                          className="btn btn-primary width-100 px-4 back-orange"
                        >
                          Add DOS
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </main>

          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
