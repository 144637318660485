import axios from "axios";
import { useFormik } from "formik";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import { toast, ToastContainer } from "react-toastify";

import { UpdateProduct, addStoreLogo } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import AdminFooter from "../includes/AdminFooter";
import CheckUtype from "../includes/CheckUtype";

import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";

export default function EditRetailers() {
  const state = useSelector((state) => state.stateVals);
  const { id: userId } = state;

  const [loading, setLoading] = useState(false);
  const [price, setPrice] = useState("");
  const [dos, setDos] = useState("");
  const [storeImage, setStoreImage] = useState("");

  const { id: prodId } = useParams();
  const [allDosList, setAllDosList] = useState([]);
  const [file, setFile] = useState("");

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await AdminListService.getAllDosList();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.first_name + " " + value.last_name,
          value: value.id,
        });
      });
      setAllDosList([{ key: "SELECT DOS", value: "" }, ...results]);
    };
    getUserState();
  }, []);

  useLayoutEffect(() => {
    const getAddedProduct = async () => {
      setLoading(true);

      try {
        const { data } = await AdminListService.getRetailerById(prodId);
        const { response: res } = data;
        setPrice(res.name);
        setDos(res.dos);
        setStoreImage(res.image);

        setLoading(false);
      } catch (err) {
        console.log(err);
        if (err?.response?.status === 404) {
          toast.error("Resource Not Found!", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        } else {
          setLoading(false);
        }
      }
    };

    getAddedProduct();
  }, []);

  const handleFileChange = (e) => {
    console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    setFieldValue("storeLogo", e.target.files[0].name);
  };

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org?format=json");

    const weIp = res.data.ip;
    secureLocalStorage.setItem("ip", weIp);
  };

  const updateProduct = async (values) => {
    setLoading(true);
    try {
      const formData = new FormData();
      if (file !== "") {
        console.log(file);
        formData.append("image", file);

        formData.append("imageName", values.storeLogo);
      }

      formData.append("name", values.storeName);
      formData.append("dos", values.dos);
      formData.append("updated_by", userId);
      formData.append("updated_ip", secureLocalStorage.getItem("ip"));
      await AdminListService.updateRetailer(prodId, formData);
      setLoading(false);
      toast.success("Store Updated!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    } catch (err) {
      setLoading(false);

      if (err?.response?.data?.name?.length) {
        toast.error(err.response.data.name[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        toast.error("Unprocessable Content !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 409) {
        toast.error(err.response.data.response, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Some thing went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    enableReinitialize: true,
    initialValues: {
      storeName: price,
      dos: dos,
      storeLogo: storeImage,
    },
    validationSchema: addStoreLogo,
    onSubmit: (values, action) => {
      updateProduct(values);
    },
  });

  useLayoutEffect(() => {
    getIp();
  }, [handleSubmit]);

  return (
    <div className="semi-dark">
      <div className="wrapper">
        <CheckUtype />

        <HeaderSidebar />
        <ToastContainer />

        <main className="page-content">
          <div className="row">
            <div className="col">
              <div className="manage-heading-2">
                <h2>Edit Store</h2>
              </div>

              <div className="slides-here">
                <div className="alert alert-info">
                  Name of the Store is required
                </div>
                <div className="main-content-box">
                  <div className="manage-territories-box">
                    <form onSubmit={handleSubmit} noValidate>
                      <div className="row">
                        <div className="col-lg-6">
                          <label className="form-label">
                            DOS{" "}
                            <span className="d-inline-block text-danger">
                              *
                            </span>
                          </label>

                          <select
                            className="form-select"
                            onChange={(e) => {
                              handleChange(e);
                            }}
                            onBlur={handleBlur}
                            name="dos"
                            value={values.dos || ""}
                            required
                          >
                            {allDosList.map((res) => {
                              return (
                                <option key={res.value} value={res.value}>
                                  {res.key}
                                </option>
                              );
                            })}
                          </select>

                          {errors.dos && touched.dos ? (
                            <p className="help is-danger">{errors.dos}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6">
                          <label className="form-label">
                            Store Name{" "}
                            <span className="d-inline-block text-danger">
                              *
                            </span>
                          </label>

                          <input
                            type="text"
                            placeholder="Store Name"
                            className={`form-control ${
                              errors.storeName && touched.storeName
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            name="storeName"
                            value={values.storeName || ""}
                            required
                          />

                          {errors.storeName && touched.storeName ? (
                            <p className="help is-danger">{errors.storeName}</p>
                          ) : null}
                        </div>
                        <div className="col-lg-6 mt-3">
                          <label className="form-label">Store Logo</label>

                          <input
                            type="file"
                            placeholder="Store Name"
                            className={`form-control pt-0 ${
                              errors.storeLogo && touched.storeLogo
                                ? "is-danger"
                                : ""
                            }`}
                            onChange={handleFileChange}
                            name="storeLogo"
                          />

                          {errors.storeLogo && touched.storeLogo ? (
                            <p className="help is-danger">{errors.storeLogo}</p>
                          ) : null}
                        </div>

                        <div className="col-lg-5 mt-1 pt-1">
                          <button
                            type="submit"
                            className="btn btn-primary d-block px-4 mt-30 width-100 back-blue"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>

        <ToTop />
        <div className={`loader ${loading ? "in" : ""}`}>
          <div className="spinner-border main-spin"></div>
        </div>
      </div>
      <AdminFooter />
    </div>
  );
}
