import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { toast, ToastContainer } from "react-toastify";

import { addStoreLogo } from "../../../schema";
import AdminListService from "../../../services/admin-list.service";

import AdminFooter from "../includes/AdminFooter";
import CheckUtype from "../includes/CheckUtype";

import HeaderSidebar from "../includes/HeaderSidebar";
import ToTop from "../includes/ToTop";
import { useSelector, useDispatch } from "react-redux";
import { useLayoutEffect } from "react";
import axios from "axios";
import secureLocalStorage from "react-secure-storage";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

export default function AddRetailers() {
  const TITLE = "Casper Cash | Product";
  const [loading, setLoading] = useState(false);
  const state = useSelector((state) => state.stateVals);
  const { id } = state;
  const navigate = useNavigate();

  const [allDosList, setAllDosList] = useState([]);
  const [file, setFile] = useState("");

  useEffect(() => {
    const getUserState = async () => {
      const { data } = await AdminListService.getAllDosList();
      const { response: res } = data;
      const results = [];
      res.map((value) => {
        results.push({
          key: value.first_name + " " + value.last_name,
          value: value.id,
        });
      });
      setAllDosList([{ key: "SELECT DOS", value: "" }, ...results]);
    };
    getUserState();
  }, []);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
    setFieldValue("storeLogo", e.target.files[0].name);
  };

  const addRetailer = async (values, action) => {
    setLoading(true);

    try {
      const formData = new FormData();
      formData.append("image", file);

      formData.append("imageName", values.storeLogo);
      formData.append("name", values.storeName);
      formData.append("dos", values.dos);
      formData.append("created_by", id);
      formData.append("created_ip", secureLocalStorage.getItem("ip"));
      const response = await AdminListService.addRetailer(formData);

      if (response.status === 201) {
        setLoading(false);
        toast.success("Store Created!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        action.resetForm();
        navigate("/admin/view-store");
      }
    } catch (err) {
      console.log(err);
      if (err?.response?.data?.name?.length) {
        toast.error(err.response.data.name[0], {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 422) {
        toast.error("Unprocessable Content !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (err?.response?.status === 409) {
        toast.error(err.response.data.response, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        toast.error("Some thing went wrong!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      setLoading(false);
    }
  };

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: {
      storeName: "",
      dos: "",
      storeLogo: "",
    },
    validationSchema: addStoreLogo,
    onSubmit: (values, action) => {
      addRetailer(values, action);
    },
  });

  useLayoutEffect(() => {
    getIp();
  }, [handleSubmit]);

  const getIp = async () => {
    const res = await axios.get("https://api.ipify.org?format=json");

    const weIp = res.data.ip;
    secureLocalStorage.setItem("ip", weIp);
  };
  return (
    <>
      <Helmet>
        <title>{TITLE}</title>
      </Helmet>
      <div className="semi-dark">
        <div className="wrapper">
          <CheckUtype />

          <HeaderSidebar />
          <ToastContainer />
          <main className="page-content">
            <div className="row">
              <div className="col">
                <div className="manage-heading-2">
                  <h2>Add Store </h2>
                  <div className="alert alert-info">
                    Name of the Store is required
                  </div>
                </div>

                <div className="slides-here">
                  <div className="main-content-box">
                    <div className="manage-territories-box">
                      <form onSubmit={handleSubmit} noValidate>
                        <div className="row">
                          <div className="col-lg-6">
                            <label className="form-label">
                              DOS{" "}
                              <span className="d-inline-block text-danger">
                                *
                              </span>
                            </label>

                            <select
                              className="form-select"
                              onChange={(e) => {
                                handleChange(e);
                              }}
                              onBlur={handleBlur}
                              name="dos"
                              value={values.dos || ""}
                              required
                            >
                              {allDosList.map((res) => {
                                return (
                                  <option key={res.value} value={res.value}>
                                    {res.key}
                                  </option>
                                );
                              })}
                            </select>

                            {errors.dos && touched.dos ? (
                              <p className="help is-danger">{errors.dos}</p>
                            ) : null}
                          </div>
                          <div className="col-lg-6">
                            <label className="form-label">
                              Store Name{" "}
                              <span className="d-inline-block text-danger">
                                *
                              </span>
                            </label>

                            <input
                              type="text"
                              placeholder="Store Name"
                              className={`form-control ${
                                errors.storeName && touched.storeName
                                  ? "is-danger"
                                  : ""
                              }`}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="storeName"
                              value={values.storeName || ""}
                              required
                            />

                            {errors.storeName && touched.storeName ? (
                              <p className="help is-danger">
                                Please fill the Store Name
                              </p>
                            ) : null}
                          </div>
                          <div className="col-lg-6 mt-3">
                            <label className="form-label">
                              Store Logo{" "}
                              <span className="d-inline-block text-danger">
                                *
                              </span>
                            </label>

                            <input
                              type="file"
                              placeholder="Store Name"
                              className={`form-control pt-0 ${
                                errors.storeLogo && touched.storeLogo
                                  ? "is-danger"
                                  : ""
                              }`}
                              onChange={handleFileChange}
                              name="storeLogo"
                            />

                            {errors.storeLogo && touched.storeLogo ? (
                              <p className="help is-danger">
                                Please fill the Store Name
                              </p>
                            ) : null}
                          </div>

                          <div className="col-lg-6 mt-1 pt-1">
                            <button
                              type="submit"
                              className="btn btn-primary d-block px-4 mt-30 width-100 back-blue"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
          <ToTop />
          <div className={`loader ${loading ? "in" : ""}`}>
            <div className="spinner-border main-spin"></div>
          </div>
        </div>
        <AdminFooter />
      </div>
    </>
  );
}
